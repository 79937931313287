<template lang="pug">
.thank
	.thank-wrap
		form.form
			img(src="@/assets/anniversary_2023/thank/check.svg" alt="check")
			p.text-center
				| 謝謝您提供的訊息，我們已經收到您的資料！
				br
				| 我們會盡快安排專員與您聯絡！
			.btn-thank-group
				button(type="button" @click="downloand(childFile1)") 兒童英文繪本有聲書
				button(type="button" @click="downloand(childFile2)") 劍橋兒童英檢題庫
</template>

<script>
export default {

  name: 'ThankYouPage',

  data() {
    return {
      childFile1: 'https://wuwow-store.s3.ap-southeast-1.amazonaws.com/pictures/video/2023_jr_king_arthur_legend_the_sword_in_the_stone_voice_book',
      childFile2: 'https://drive.google.com/drive/folders/1r44eZ3fCoXjUKbv1DZe3rTI8vf-9t1O1?usp=drive_link',
      body: null,
      script: null,
      noscript: null,
    };
  },
  created() {
    this.body = document.querySelector('body');
    this.script = document.createElement('script');
    this.script.innerHTML = `
   		window._megoaa = window._megoaa || [];
         	window._megoaa.push({ type: 'event', name: 'wuwowjr_CV', acid: '26508', pxd: '854697138381854'});
   	`;
    this.noscript = document.createElement('noscript');
    this.noscript.innerHTML = `
   	<img src='//trace.popin.cc/api/bidder/track/pixel/conversion?cvn=wuwowjr_CV&acid=26508&pxd=854697138381854&tn=f9f2b1ef23fe2759c2cad0953029a94b' width='0' height='0' style='display:none' />
   	`;
    this.body.appendChild(this.script);
    this.body.appendChild(this.noscript);
  },
  destroyed() {
    this.body.remove(this.script);
    this.body.remove(this.noscript);
  },
  methods: {
    downloand(file) {
      const a = document.createElement('a');
      a.setAttribute('href', file);
      a.setAttribute('download', '');
      a.setAttribute('target', '_blank');
      a.click();
      a.remove();
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin sm {
  @media (min-width: 475px) {
    @content;
  }
}

@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 1240px) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: 1680px) {
    @content;
  }
}

@mixin xxxl {
  @media (min-width: 1920px) {
    @content;
  }
}
.thank {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-image:
		linear-gradient(90deg, #94E0D3 0.42%, rgba(148, 224, 211, 0.00) 100%),
		url('../../../../assets/anniversary_2023/thank/thank.png');
	background-size: cover, cover;
	background-repeat: no-repeat;
}
.thank-wrap {
	// max-width: 569px;
	// margin: 0 auto;
	padding: 0 16px;
	@include md {
		padding: 0;
	}
}
.form {
	background: rgba(255, 255, 255, 0.4);
	border: 1px solid #fff;
	border-radius: 20px;
	text-align: center;
	width: 100%;
	padding: 16px;
	@include lg {
		padding: 40px;
		width: 569px;
	}
	img {
		margin-bottom: 40px;
	}
	p {
		font-size: 14px;
		line-height: 20px;
		padding: 40px 0;
		background: rgba(255,255,255, 0.6);
		border-radius: 15px;
		margin-bottom: 40px;
	}
	button {
		appearance: none;
		border: none;
		outline: none;
		background: #2AB291;
		color: #fff;
		border-radius: 5px;
		padding: 15px 0;
		width: 100%;
		max-width: 322px;
		&:first-child {
			margin-bottom: 20px;
		}
		&:hover {
			background: lighten(#2AB291, 20%);
		}
	}
}
.btn-thank-group {
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
